// * .TTF或.OTF，适用于Firefox 3.5、Safari、Opera
// * .EOT，适用于Internet Explorer 4.0+
// * .SVG，适用于Chrome、IPhone

// 针对“日文”样式的调整，必须写在全局样式的根结点中

// 字体地址:https://gwfh.mranftl.com/fonts/noto-sans-jp?subsets=japanese,latin

// 日文站很少使用加粗体，直接引用粗体
/* noto-sans-jp-100 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Light';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/NOTOSANSJP/d04bce4e1a18f6c9b72786dc05ba441b.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/d04bce4e1a18f6c9b72786dc05ba441b.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/7f680b0e3e6c06d0af87ebadba274468.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/b1ec1f0181f0bb3f432926da27203043.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/c0a5d9e437fc4b78ebe4b74274d035ad.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-300 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-ExtraLight';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/NOTOSANSJP/6cd1be401d14f5a38af929e8992f9711.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/6cd1be401d14f5a38af929e8992f9711.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/fcfed0437b8e5494df25ad45a158f33a.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/528334c8d0936dff2fdde7b46e3fd16b.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/3c880a4950ec774c0e8499b2f819e9fc.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-regular - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/NOTOSANSJP/6e0478e363efc707e1e70c9bbfc31a88.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/6e0478e363efc707e1e70c9bbfc31a88.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/912cef891894d66dd7186ab8e05b885c.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/f055f1d0656eb6ea7dd607dffcccfa39.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/NOTOSANSJP/6e0478e363efc707e1e70c9bbfc31a88.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/6e0478e363efc707e1e70c9bbfc31a88.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/912cef891894d66dd7186ab8e05b885c.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/f055f1d0656eb6ea7dd607dffcccfa39.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-500 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-SemiBold';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/NOTOSANSJP/04ec06eadb9564ad852255600af8b8b9.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/04ec06eadb9564ad852255600af8b8b9.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/21c686a7b8d9bcd144f225105bf14787.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/b0bff80f0889eef1f7ee73b2934c6efd.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/NOTOSANSJP/589ed27f807e25831b9d2417886ffd11.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/589ed27f807e25831b9d2417886ffd11.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/d64fa73d0d6c25ff20ba685ff1c70123.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/d075b5267857ed06aa1c32a2bca9d10a.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/16a4d25a39c85b91db83a6a1a6035578.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-900 - latin_japanese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'jp-Ef-Font-ExtraBold';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/NOTOSANSJP/f96410da227918781db98d52e53b5e14.eot'); /* IE9 Compat Modes */
    src: url('/fonts/NOTOSANSJP/f96410da227918781db98d52e53b5e14.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/NOTOSANSJP/f26fe547fc7eaa81f24b17ada82970a4.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/fonts/NOTOSANSJP/7b1011366dcef510d9d24fcdb6d7ca2e.woff') format('woff'),
        // /* Modern Browsers */ url('/fonts/NOTOSANSJP/noto-sans-jp-v42-latin_japanese-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/fonts/NOTOSANSJP/f62986b876bd71c3554f1ea0592d47d5.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}


// 2023-09-04
@font-face{
    font-family: 'JP-NOTOSANS-woff2-ExtraBold';
    font-weight: 900;
    src: url('/fonts/NOTOSANSJP/f26fe547fc7eaa81f24b17ada82970a4.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Bold';
    font-weight: 700;
    src: url('/fonts/NOTOSANSJP/d64fa73d0d6c25ff20ba685ff1c70123.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-SemiBold';
    font-weight: 500;
    src: url('/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Medium';
    font-weight: 400;
    src: url('/fonts/NOTOSANSJP/f49ac211a04c6a93ac940f877e8efdbb.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Regular';
    font-weight: 300;
    src: url('/fonts/NOTOSANSJP/86fe8ce489ba722b7016ec47320ae9d6.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'JP-NOTOSANS-woff2-Light';
    font-weight: 200;
    src: url('/fonts/NOTOSANSJP/fcfed0437b8e5494df25ad45a158f33a.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}