@import 'lowcode/common/css/devices.scss';

.editor-space {
    --space-T1: 110px;
    --space-T2: 80px;
    --space-T3: 60px;
    --space-bg: transparent;
    height: var(--space-T1);
    background-color: var(--space-bg);
    @include devices(desktop) {
        height: var(--space-T2);
    }
    @include devices(pad) {
        height: var(--space-T3);
    }
}