@import './devices.scss';
@import './eco-swiper.scss';
@import './eco-swiper-2.scss';

:root {
    // 容器布局
    --eco-design-container-width: 1440px;
    --eco-design-container-padding: 0 120px;

    // 字体容器 996 + 48 * 2
    --eco-design-text-width: 1236px;

    // 多栏图文图片高度
    --eco-design-card-img-height: 540px;

    // 通用小卡片宽度
    --eco-design-card-width: 384px;

    // 按钮
    --eco-design-button-h: 32px;

    // 按钮
    --eco-design-button-h-small: 28px;
    --eco-design-button-padding-small: 0 16px;
    --eco-design-button-font-small: 12px;

    --eco-design-button-h-medium: 36px;
    --eco-design-button-padding-medium: 0 18px;
    --eco-design-button-font-medium: 14px;

    --eco-design-button-h-large: 42px;
    --eco-design-button-padding-large: 0 20px;
    --eco-design-button-font-large: 16px;

    --eco-design-button-h-extra-large: 48px;
    --eco-design-button-padding-extra-large: 0 24px;
    --eco-design-button-font-extra-large: 20px;

    // 上下间隔
    --eco-design-space-h: 180px;

    // 圆角
    --eco-design-radius: 4px;

    // 页面背景色
    --eco-design-page-bg-black: #000;
    --eco-design-page-bg-white: #fff;
    --eco-design-page-bg-gray: #F5F5F6;

    // 段落-颜色
    --eco-design-color-label: #000;
    --eco-design-color-title: #000;
    --eco-design-color-subTitle: #696C71;
    --eco-design-color-desc: #696C71;

    // 段落-字体大小-pc
    --eco-design-size-label-pc: 20px;
    --eco-design-size-title-pc: 34px;
    --eco-design-size-subTitle-pc: 20px;
    --eco-design-size-desc-pc: 20px;
    // 段落-字体大小-h5
    --eco-design-size-label-h5: 16px;
    --eco-design-size-title-h5: 24px;
    --eco-design-size-subTitle-h5: 16px;
    --eco-design-size-desc-h5: 16px;

    // 颜色
    --eco-design-color-error: red;
    --eco-design-color-warning: red;
    --eco-design-color-theme: #06c;
    --eco-design-color-border: #ddd;

    @include devices(desktop) {
        // 上下间隔
        --eco-design-space-h: 140px;
        // 多栏图文图片高度
        --eco-design-card-img-height: 507px;
        // 通用小卡片宽度
        --eco-design-card-width: 359px;
    }
    @include devices(pad) {
        --eco-design-container-width: 100vw;
        --eco-design-container-padding: 0 48px;

        // 多栏图文图片高度
        --eco-design-card-img-height: 302px;

        // 通用小卡片宽度
        --eco-design-card-width: auto;

        // 字体
        --eco-design-text-width: 100%;
        // 上下间隔
        --eco-design-space-h: 120px;
    }
    @include devices(h5) {
        --eco-design-container-padding: 0 24px;
        // 多栏图文图片高度
        --eco-design-card-img-height: 280px;
    }
}

// 通用容器
.eco-content {
    max-width: var(--eco-design-container-width);
    padding: var(--eco-design-container-padding);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

// 标题去掉默认样式
p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
