
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import './animate.scss';
@import './icons.scss';
@import './efpic.scss';
@import './css-ani/ef-ani-common-section.scss';
@import 'elem/ui/ef-link/index.scss';

:root {
    /* 主色系 */
    --navbg: #03060b;
    --global: #2673ff;
    --cColor: #8191a4;
    --subColor: #2673ff;

    /* 深黑 */
    --cBlack: #000;
    --cBlack01: #333;

    /* 浅黑 */
    --cBlack02: #666;

    /* 灰色 */
    --cGrey01: #999;

    /* 中灰 */
    --cGrey02: #ccc;

    /* 中浅灰 */
    --cGrey03: #dadada;

    /* 浅灰 */
    --cGrey04: #ededed;

    /* 弱灰 */
    --cGrey05: #fafafa;

    /* 白色 */
    --cWhite: #fff;

    /* 边框 */
    --cborder: #ebe9e9;
    --Bborder: #ededed;

    /* 产品详情页header高度 */
    --r-detail-nav-height: 62px;

    /* 产品详情页移动端header高度 */
    --r-detail-mobile-nav-height: 0.44rem;

    /* PC端header高度 */
    --header-height: 60px;

    /* 微逆预热页header高度 */
    --power-stream-teaser-height: 32px;

    /* 微逆预热页吸顶高度 */
    --email-sticky-height: 96px;

    /* 最大宽度-通用容器 */
    --ef-width-pc: 1296px;
    --ef-width-desktop: 1206px;
    --ef-width-pad: calc(100vw - 96px);
    --ef-width-h5: calc(100vw - 48px);

    /* 最大宽度-文本 */
    --ef-width-text-pc: 1056px;
    --ef-width-text-desktop: 830px;
    --ef-width-text-pad: 596px;

    /* pc端边距 */
    --ef-padding-pc: 0 48px;
    --ef-padding-pad: 0 48px;
    --ef-padding-h5: 0 24px;
    
    /* 左右轮播时pc端的左右边距 */
    --ef-padding-swiper-pc: max(128px, calc(50vw - 600px));
}

html {
    background-color: #f8f8f8;
    // 适配方案--rem
    font-size: calc(100vw / 3.75);
    scroll-behavior: smooth;

    html[site='cn'] & {
        font-weight: 400;
    }
}

@media only screen and (min-width: 750px) {
    html {
        font-size: 200px;
    }
}

html,
body {
    display: revert;
    color: var(--navbg);
    height: auto;
    position: relative;
}

body {
    min-width: 360px;
    font-size: 14px;
}

input,
button,
textarea {
    font-size: 14px;
    color: var(--cColor);
}

html {
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    code,
    del,
    dfn,
    em,
    img,
    q,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    caption,
    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section,
    [type='button'],
    [type='submit'],
    [type='text'],
    [type='textarea'],
    [type='file'],
    [type='number'],
    [type='email'],
    [type='password'],
    i,
    em,
    s,
    strong,
    b {
        margin: 0px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        //font-weight: normal;
        font-style: normal;
        -webkit-appearance: none;
        -webkit-box-shadow: none;
        line-height: inherit;
        list-style: none;
        outline: 0;
        text-decoration: none;
        // word-spacing: -1px; // 全局添加单词间间距
        // letter-spacing: 0.3px; // 全局添加字母间间距
    }
}

select,
input {
    vertical-align: middle;
}

textarea {
    resize: none;
}

table {
    border-collapse: collapse;
}

* {
    list-style: none;
}

.clear {
    *zoom: 1;
}

.clear:before,
.clear:after {
    content: '';
    display: table;
}

.clear:after {
    clear: both;
}

.clearfix::after {
    content: '';
    display: block;
    clear: both;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html a {
    color: var(--navbg);
    text-decoration: none;
    outline: none;
}

html a:hover {
    color: inherit;
    outline: none;
    text-decoration: none;
}

.header a {
    color: var(--navbg);
    text-decoration: none;
    outline: none;
}

.ant-form-item-control-input-content a,
.ant-form-item-control-input-content a:hover {
    color: var(--navbg);
    text-decoration: none;
    outline: none;
}

input,
button {
    outline: none;
    border: none;
    box-sizing: border-box;
}

input {
    width: 20px;
    height: 20px;
    display: inline;
    vertical-align: middle;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.container {
    width: 1120px;
    margin: 0 auto;

    @include maxWidth('lg') {
        width: 100%;
    }
}

.section-container {
    width: auto;
    max-width: 1380px;
    margin: 0 auto;
}

.tl {
    text-align: left;
}

.tc {
    text-align: center;
}

.tr {
    text-align: right;
}

.fullImgs {
    object-fit: cover;
    object-position: center;
}

.signFullImgsBox {
    position: relative;
}

.signFullImgsBox > div > img {
    object-fit: cover;
    object-position: center;
}

.banner-center {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

a > .ant-btn {
    cursor: pointer;
}

.mr20 {
    margin-right: 20px;
}

/* 处理超出字符 */
.strLen {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.strLen2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 按钮 */
.default-btn.ant-btn {
    background: var(--global);
    border: 1px solid var(--global);
    color: #fff;

    &:hover,
    &:focus {
        background: var(--sub-color);
        border-color: var(--sub-color);
    }
}

.round {
    border-radius: 17px !important;

    .ant-select-selector {
        border-radius: 22px !important;

        .ant-select-selection-search-input {
            height: 44px !important;
        }
    }
}

.small {
    min-width: 54px;
}

.msmall {
    width: 116px;
    height: 34px;
}

.middle {
    width: 256px;
    height: 44px;
}

.ant-form-item-control-input .large,
button.large {
    width: 374px;
    height: 56px;
}

.middle-select {
    width: 276px;
    height: 56px;
}

.large-select {
    width: 441px;
    height: 44px;
}

html .info-btn {
    background: var(--cGrey01);
    border-color: var(--cGrey01);

    &:hover {
        background: var(--cBlack01);
        border-color: var(--cBlack01);
    }
}

html .black-btn {
    background: var(--navbg);
    border-color: var(--navbg);

    &:hover,
    &:visited,
    &:focus,
    &:active {
        background: var(--cBlack);
        border-color: var(--cBlack);
    }
}

html .link-btn {
    font-size: 17px;
    background: none !important;

    span {
        color: var(--cBlack);
    }

    &:hover {
        background: none !important;

        span {
            text-decoration: underline 1px;
        }
    }
}

html .black-link-btn {
    font-size: 16px;
    background: none;

    span {
        color: var(--navbg);
    }

    &:hover {
        background: none;

        span {
            color: var(--global);
        }
    }
}

html .black-hollow {
    border-color: var(--navbg);
    background: #fff;

    span {
        text-shadow: none;
        color: var(--navbg);
        word-spacing: 0px;
    }

    &:hover {
        background: none;
        border-color: var(--navbg);
    }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none !important;
}

.ant-select-dropdown {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include maxWidth('lg') {
        border-radius: 0.08rem;
    }

    .ant-select-item-group {
        margin-top: 10px;
        font-size: 17px;
        color: #d1d3d5;
        background: none;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: transparent;
    }

    .ant-select-item-option {
        &:hover {
            background-color: #f8f8f8;
        }
    }

    .ant-select-item-option-disabled.ant-select-item-option-selected {
        background: #fff;

        &:hover {
            background-color: #f8f8f8;
        }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: none !important;
    }

    .rc-virtual-list-scrollbar-thumb {
        background-color: #e5e5e5 !important;
    }
}

.searchDropdown {
    border-radius: 0 0 8px 8px;
    height: 310px;
    box-shadow: 0 16px 30px -12px rgba(0, 0, 0, 0.1), 0 0 16px -5px rgba(0, 0, 0, 0.03) !important;
    padding: 0;

    .recommend {
        line-height: 44px;
        padding-left: 60px;
        color: #919191;
    }

    .rc-virtual-list {
        height: 302px;

        .rc-virtual-list-holder {
            max-height: 100% !important;

            .rc-virtual-list-scrollbar-thumb {
                background-color: #e5e5e5;
            }
        }
    }

    .ant-select-item-option-content {
        line-height: 44px;
        font-size: 14px;
    }

    .ant-select-item {
        padding: 0 0 0 60px;
        font-family: Manrope-Light, Ef-Font-Light, 'Arial', sans-serif;
        font-weight: 300;
        color: var(--navbg);

        html[site='cn'] & {
            font-weight: 400;
        }
    }

    .ant-select-item-group {
        line-height: 44px;
        font-size: 14px;
        color: #919191;
    }

    .ant-select-item-empty {
        position: relative;
        min-height: 302px;
        height: 302px;

        .ant-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.scrollama__debug-offset {
    display: none !important;
}

// 删除地址提示类弹窗
.address-modal-wrapper {
    .ant-modal-content {
        background-color: transparent;
    }

    .delete-tip-text {
        font-family: Manrope-Medium, Ef-Font-Medium, 'Arial', sans-serif;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
    }

    .btn-action {
        margin-top: 32px;
        text-align: center;

        Button {
            cursor: pointer;
        }

        .delete-btn-default {
            height: 36px;
            padding: 5px 28px 4px;
            border-radius: 22px;
            border: solid 1px var(--navbg);
            font-family: Manrope-Medium, Ef-Font-Medium, 'Arial', sans-serif;
            font-size: 17px;
            margin: 0 16px;
            font-weight: 600;

            &.ok {
                &:hover {
                    color: var(--navbg);
                }
            }

            &.cancel {
                color: #fff;
                background-color: var(--navbg);

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

div.ant-modal {
    top: 130px;
}

// 基础弹窗样式
.ef-base-modal {
    .ant-modal-close {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: 20px;
        right: 20px;

        .ant-modal-close-x {
            width: 20px;
            height: 20px;
            line-height: 20px;
            background-color: #919191;
            border-radius: 10px;
            font-size: 11px;
            padding-top: 1px;
            color: #fff;

            &:hover {
                background-color: #121212;
            }
        }
    }

    .ant-modal-content {
        height: 100%;
        padding: 20px;
        border-radius: 24px;
        box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.38);
    }

    .anticon-close-circle {
        font-size: 20px;
    }

    .ant-modal-body {
        padding: 0;
    }
}

.ef-base-mobile-modal {
    // 弹窗图层
    &.ant-modal-wrap {
        z-index: 2000;
    }

    .ant-modal-close {
        width: 0.44rem;
        height: 0.44rem;
        top: 0;
        right: 0;
        border-radius: 0.1rem;

        .ant-modal-close-x {
            width: 0.44rem;
            height: 0.44rem;
            line-height: 0.2rem;
            background-color: transparent;
        }
    }

    .ant-modal-content {
        height: 100%;
        padding: 0.6rem 0.2rem 0.2rem;
        border-radius: 0.16rem;
    }

    .ant-modal-body {
        padding: 0;
    }
}

// 下拉框--项
.ef-select-item {
    .ant-select-item {
        padding: 11px 12px;
        color: #666;
        font-family: Manrope-Regular, Ef-Font-Regular, 'Arial', sans-serif;
        font-weight: 400;
    }

    .ant-select-item:hover,
    .ant-select-item:focus {
        color: #000;
        font-weight: 600;
    }

    .ant-select-item.ant-select-item-option-selected {
        color: #000;
    }
}

.ef-select-item-back {
    .ant-select-item {
        padding: 11px 12px;
        color: #666;
        font-family: Manrope-Regular, Ef-Font-Regular, 'Arial', sans-serif;
        font-weight: 400;
        text-align: left;
    }
    .ant-select-item.ant-select-item-option-selected {
        color: #fff;
    }

    .ant-empty-description {
        color: #666;
    }
}

.ant-btn-primary:not([disabled]):hover {
    background-color: #236bee !important;
    border-color: #236bee !important;
}

.productInfo,
.pressInfo {
    a {
        &:hover {
            text-decoration: underline 1px !important;
        }
    }
}

.item-box {
    .black-btn {
        &:hover {
            background-color: #444 !important;
            border-color: #444 !important;
        }
    }
}

.box-img {
    div {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}

img {
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /*Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
}

.imgLoadingBg {
    background-color: #ededed;
}

// 滚动动画-gsap
.ef-scroll-ani-total-box {
    position: sticky;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.ef-scroll-ani {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    will-change: opacity;

    .ef-scroll-ani-trigger {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ef-scroll-ani-inter {
        position: relative;
    }
}

// 蓝色链接按钮
.ef-blue-link,
.ef-blue-link > a {
    display: inline-block;
    vertical-align: top;
    color: #2164ff;
    font-size: 17px;
    line-height: 24px;

    .ef-blue-link-more {
        display: inline-block;
        vertical-align: middle;
        line-height: 14px;
        margin: -2px 0 0 7px;

        svg {
            vertical-align: top;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

/*.product_common_0426 {
    visibility: hidden;
}

.show_page_0426 .product_common_0426 {
    visibility: visible;
}*/

.ant-back-top {
    width: 40px;
    height: 40px;
    position: fixed;
    right: 20px;
    bottom: 129px;
    z-index: 2000;
    background-color: #000;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 0.5;
    cursor: pointer;

    svg {
        margin: -1px 0 0 -1px;
    }

    .ant-back-top-content {
        float: right;
        min-width: auto;
    }
}

.acsb-trigger.acsb-trigger-size-small,
.acsb-trigger.acsb-trigger-size-medium,
.acsb-trigger.size-small {
    width: 40px !important;
    height: 40px !important;
    z-index: 99999;
    inset: auto 22px 135px auto !important;
}

.ecoflow-mobile {
    .headerh5 {
        display: block;
    }
    .acsb-trigger.acsb-trigger-size-small,
    .acsb-trigger.acsb-trigger-size-medium,
    .acsb-trigger.size-small {
        width: 40px !important;
        height: 40px !important;
        z-index: 99999;
        inset: auto 22px 22px auto !important;
    }

    .ant-back-top {
        right: 22px;
        bottom: 280px;
        z-index: 2000;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        opacity: 0.5;

        // 2022-07-01: => D2: 要求与计算器大小一致
        // https: //docs.google.com/spreadsheets/d/1QlGfVY95LYU60v7wkm-9GbKuUvSm63vFtVefwzyft0w/edit#gid=0
        .ant-back-top-content {
            border-radius: 50%;
            width: 40px;
            height: 40px;

            .ant-back-top-icon {
                line-height: 0.56rem;
                font-size: 0.32rem;
            }
        }
    }
}

.ant-back-top:empty {
    display: none;
}

.ecoflow-pc {
    visibility: visible;
    .headerH5 {
        display: none;
    }
    .footerh5 {
        display: none !important;
    }

    // 2022-07-01: => D2: 要求与计算器大小一致
    // https: //docs.google.com/spreadsheets/d/1QlGfVY95LYU60v7wkm-9GbKuUvSm63vFtVefwzyft0w/edit#gid=0
    .ant-back-top {
        border: 1px solid rgba(255, 255, 255, 0.5);
        right: 22px;
        bottom: 255px;
        z-index: 2000;

        .ant-back-top-content {
            border-radius: 50%;
            width: 66px;
            height: 66px;
            float: right;
            min-width: auto;

            .ant-back-top-icon {
                line-height: 66px;
                font-size: 40px;
            }
        }
    }

    .index-bannersH5 {
        .swiper-button-prev,
        .swiper-button-next {
            display: none !important;
        }
    }
}

.ef-tabs-mod {
    .ef-tabs-mod-pabe {
        display: none;
    }

    .ef-tabs-mod-pabe.active {
        display: block;
    }
}

.ef-global-tools {
    position: fixed;
    right: 22px;
    bottom: 190px;
    width: 40px;
    text-align: center;
    z-index: 10;

    .ef-global-tools-item-email {
        display: none;
        font-size: 40px;
        cursor: pointer;
    }
}

// 全屏锁屏
.ef-has-modal-full-viewport {
    height: 100%;
    overflow: hidden;
    .ecoflow-frame-totop {
        display: none;
    }
}

.ef-fixed-win {
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: #fff;
    display: none;

    .ef-fixed-win-close {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 22;
        width: 26px;
        height: 26px;
        cursor: pointer;
        text-align: center;
        line-height: 26px;
        font-size: 26px;
    }

    .ef-fixed-win-body {
        position: relative;
        z-index: 9;
        height: 100%;
        padding-top: 100px;
        overflow: auto;
        color: #03060b;
    }

    .ef-fixed-win-body-inter {
        width: 1140px;
        margin: 0 auto;
    }

    &.ef-fixed-win-active {
        display: block;
    }
}

.ef-link__page {
    color: #478fff;
    cursor: pointer;
    position: relative;
    z-index: 2;
    &.is-mobile {
        text-decoration: underline !important;
    }

    &:hover {
        color: #478fff;
        text-decoration: underline;
    }
}

// 针对 ant 的 from 表单的多重渲染问题
.login_page_a {
    .ant-row > .ant-row {
        display: block !important;
        width: 100%;
    }
}

.login_page_a #normal_login .form-item2 .ant-form-item-control-input-content,
.login_page_a #normal_login .form-item2 .ant-form-item-control-input {
    display: flex !important;
    justify-content: space-around !important;
    width: 100% !important;
}

style + iframe[style*='webSDKOnLoad'] {
    display: none;
}

.ef-region-modal-pc {
    .ant-modal-content {
        margin: 120px 0 40px;
        border-radius: 20px;
        box-shadow: 0px 20px 30px -15px rgba(0, 0, 0, 0.38);
    }
}

.swiper-wrapper .swiper-slide {
    transform: translate3d(0, 0, 0);
}

.dropDownMenuH5 {
    .ant-collapse-content-box {
        max-height: calc(100vh - 4rem);
        overflow-y: scroll;
    }
}
.memberdays-dropDownIcon {
    // border-radius: 4px;
    .ant-popover-arrow-content, .ant-popover-inner-content  {
        background: linear-gradient(0deg, #717174 0%, #717174 100%), #cfd1d4;
    }
    .ant-popover-inner-content {
        max-width: 258px;
        font-size: 12px;
        color: #fff;
        padding: 16px;
        box-shadow: 3px -3px 7px 0px rgba(0, 0, 0, 0.2);
    }
}

.overflow-hidden {
    height: 100vh;
    overflow: hidden;
}

// 产品详情页
.product_common_real {
    background-color: #fff;
}

// css 写的三角箭头
.ef-arricon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 6px;
    height: 6px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(135deg);
}

.visuallyhidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
}
.will-change {
    will-change: transform, opacity;
}

// 隐藏滚动条样式
.hidden-scrollbar {
    scrollbar-width: none; /* Firefox 兼容*/
    -ms-overflow-style: none; /* IE 10+ 兼容*/
    &::-webkit-scrollbar {
        display: none; /* Chrome Safari 兼容*/
    }
}
.ant-modal-root {
    position: relative;
    z-index: 1100;
}
// 针对不同语种的调整
@import './locales/en.scss';

// 针对不同分辨率的调整，小于等于某像素（max-width）
@import './breakpoints/xxl.scss';
@import './breakpoints/xl.scss';
@import './breakpoints/lg.scss';
@import './breakpoints/md.scss';
@import './breakpoints/sm.scss';
@import './breakpoints/xs.scss';
@import './h5global.scss';
@import '../element/index.scss';

// 针对不同语种的调整
@import './global-locales.scss';

// onetruest cookie方案修正
#onetrust-banner-sdk button,
#onetrust-pc-sdk button,
#ot-sdk-cookie-policy button {
    margin-bottom: 1em !important;
}
#onetrust-pc-sdk #ot-lst-cnt {
    margin-top: 1em !important;
}

// 元旦 活动
// .page-layout.ef-chrismasNewYearBar,
.page-layout.ef-referralBar {
    .ecoflow-frame-header {
        top: 60px;
    }
    .down_nav {
        top: 60px;
    }
    .product_naver.frost {
        top: 60px !important;
    }
}
// 不吸顶的导航
// .ef-chrismasNewYearBar.ecoflow-isNoFixed,
// .ef-chrismasNewYearBar.product_common_real,
.ef-referralBar.ecoflow-isNoFixed,
.ef-referralBar.product_common_real {
    .ecoflow-frame-header {
        top: 0;
    }
}

// 黑色滚动条
.scrollbar-editor-black::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    overflow: visible;
    background-color: transparent;
    box-shadow: inset 1px 1px 0 transparent;
}
.scrollbar-editor-black::-webkit-scrollbar-track {
    background: transparent;
}
.scrollbar-editor-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #626262;
    background-clip: padding-box;
}

body.__inwebsite__ {
    .ecoflow-frame-header {
        display: none !important;
    }
    footer.footer {
        display: none !important;
    }
    #onetrust-consent-sdk {
        display: none !important;
    }
    .signup-popup {
        display: none !important;
    }
    #nest > .css-142caj0 {
        display: none !important;
    }
    .announcement-bar {
        display: none !important;
    }
    .page-layout.ef-referralBar .product_naver.frost {
        top: 0 !important;
    }
    .page-layout.ef-chrismasNewYearBar .product_naver.frost,
    .page-layout.ef-referralBar .product_naver.frost {
        top: 0 !important;
    }

    .referral-popup {
        display: none !important;
    }

    .consultation-fixed-icon {
        bottom: 100px !important;
        @media (max-width: 768px) {
            bottom: 80px !important;
        }
    }
}

body.ecoflow-app {
    .ecoflow-frame-header {
        display: none !important;
    }
    footer.footer {
        display: none !important;
    }
    .ecoflow-frame-header .regionPopup {
        display: none !important;
    }
}

.glo-bar-left-box {
    position: fixed;
    left: 56px;
    bottom: 56px;
    z-index: 111;
    min-width: 100px;
    @media (max-width: 1024px) {
        left: 24px;
        bottom: 24px;
    }
}

.glo-bar-right-box {
    position: fixed;
    right: 56px;
    top: 320px;
    z-index: 111;
    min-width: 48px;
    @media (max-width: 1024px) {
        right: 24px;
        top: 180px;
    }
}
.glo-bar-win-box {
    position: relative;
    z-index: 2101;
}

body:has(.activeModal.modal-ecoflow-power-kits) .announcement-bar {
    opacity: 0;
    pointer-events: none;
}

body:has(.product-brief-guide-info  .quote-win-float) .glo-bar-left-box {
    display: none !important;
}