// xs: 360
@include maxWidth('xs') {
    .section-container {
        max-width: none;
    }
    /*分类弹窗*/
    .dropDownMenu .ant-popover-content {
        width: 100vw;
    }
    .dropDownMenu .ant-popover-content .content {
        padding: 0 0 36px 0;
    }
    .dropDownMenu .ant-popover-inner-content {
        padding-left: 8px;
        padding-right: 8px;
    }
    .dropDownMenu .ant-popover-content .category {
        padding: 8px;
    }
    .dropDownMenu .ant-popover-content .product {
        width: 120px;
    }
    .dropDownMenu .ant-popover-content .product .itemsList span {
        font-size: 12px;
    }
    /*antd 全局提示弹窗*/
    .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 8px auto;
    }
    .ant-modal-body {
        max-width: 100% !important;
    }
}
