@import 'lowcode/common/css/devices.scss';

// 弹窗内容
.sales-messages {
    .sales-label {
        display: inline-flex;
        font-size: 16px;
        line-height: 1.2;
        font-family: var(--ff-woff2-EB);
        margin-bottom: 6px;
        border-radius: 4px;
        background: linear-gradient(91deg, #F88B0C 0.15%, #F8200C 97.53%);
        color: #fff;
        padding: 4px 10px;
    }
    .sales-subTitle {
        font-size: 16px;
        line-height: 1.2;
        font-family: var(--ff-woff2-EB);
        color: #F8200C;
        margin-bottom: 6px;
    }
    .sales-title {
        font-size: 28px;
        line-height: 1.1;
        font-family: var(--ff-woff2-EB);
        color: #fff;
    }
    .sales-desc {
        font-size: 16px;
        line-height: 1.2;
        font-family: var(--ff-woff2-M);
        color: #fff;
        margin-top: 12px;
        text-align: center;
    }
    .sales-specialText {
        font-size: 20px;
        line-height: 1.2;
        font-family: var(--ff-woff2-B);
        color: #F7A40A;
        margin-top: 12px;
        text-align: center;
    }
    .sales-btns {
        display: flex;
        gap: 24px;
        margin-top: 16px;
        justify-content: center;
    }
    .sales-btn {
        display: flex;
        white-space: nowrap;
        justify-content: center;
        min-width: 144px;
        line-height: 19px;
        padding: 10px 12px;
        font-family: var(--ff-woff2-B);
        font-size: 16px;
        border-radius: 4px;
        box-sizing: border-box;
        text-align: center;
        &.sales-btn-buy {
            background-color: #fff;
            color: #000;
        }
        &.sales-btn-more {
            border: 1px solid #fff;
            color: #fff;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    @include devices(desktop) {
        .sales-label {
            font-size: 14px;
        }
        .sales-subTitle {
            font-size: 14px;
        }
        .sales-title {
            font-size: 24px;
        }
        .sales-desc {
            font-size: 14px;
            margin-top: 10px;
        }
        .sales-specialText {
            font-size: 18px;
        }
    }
    @include devices(pad) {
        .sales-label {
            font-size: 12px;
        }
        .sales-subTitle {
            font-size: 12px;
        }
        .sales-title {
            font-size: 20px;
        }
        .sales-desc {
            font-size: 12px;
            margin-top: 6px;
        }
        .sales-specialText {
            font-size: 14px;
            margin-top: 6px;
        }
        .sales-btns {
            gap: 14px;
        }
        .sales-btn {
            min-width: 108px;
            font-size: 14px;
            line-height: 15px;
        }
    }
}