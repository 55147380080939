$pc1440: 1440px;
$pc1024: 1024px;
$pad768: 768px;
$m375: 375px;

@mixin devices($type) {
    // 1440+ 超大屏
    @if $type == desktop-max {
        @media only screen and (width > $pc1440) {
            @content;
        }
    }

    @if $type == pc {
        @media only screen and (width > $pc1440) {
            @content;
        }
    }

    // [1024 ~ 1440] pc
    @if $type == desktop {
        @media (width <= $pc1440) {
            @content;
        }
    }

    // (0 ~ 1024) pad
    @if $type == pad {
        @media (width < $pc1024) {
            @content;
        }
    }

    // [0 ~ 768) h5
    @if $type == h5 {
        @media (width < $pad768) {
            @content;
        }
    }

    // [768 ~ 1024] only desktop
    @if $type == onlyPc {
        @media (width >= $pc1024) {
            @content;
        }
    }

    // [1024 ~ 1044] only desktop
    @if $type == onlyDesktop {
        @media ((width >= $pc1024) and (width <= $pc1440)) {
            @content;
        }
    }

    // // [768 ~ 1024] only pad
    @if $type == onlyPad {
        @media ((width >= $pad768) and (width < $pc1024)) {
            @content;
        }
    }
}
