// swiper 插件
.eco-swiper-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    margin-top: 40px;
    @include devices(pad) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
    }
}
// 分页
.eco-swiper-pagination {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    .swiper-pagination-bullet {
        display: flex;
        width: 10px;
        height: 10px;
        background-color: #696c71;
        opacity: 0.4;
        border-radius: 50%;
        cursor: pointer;
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}
.eco-swiper-pagination-black {
    .swiper-pagination-bullet {
        background-color: #83868d;
    }
}

// 左右
.eco-swiper-navigation {
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-left: auto;
    .eco-swiper-button-next, .eco-swiper-button-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background-color: #F5F5F6;
        color: #18191B;
        border-radius: 50%;
        font-size: 28px;
        cursor: pointer;
    }
    .eco-swiper-button-prev {
        .eco-swiper-button-icon {
            transform: rotate(180deg);
        }
    }
    .eco-swiper-button-prev.swiper-button-disabled, .eco-swiper-button-next.swiper-button-disabled {
        cursor: default;
        opacity: 0.5;
    }
    @include devices(pad) {
        position: static;
        justify-content: flex-end;
        gap: 20px;

        .eco-swiper-button-next, .eco-swiper-button-prev {
            width: 40px;
            height: 40px;
        }
    }
}
.eco-swiper-control-black {
    .eco-swiper-button-prev, .eco-swiper-button-next {
        background-color: #4C4E52;
        color: #F5F5F6;
    }
}
