@import 'lowcode/common/css/devices.scss';

.emarsys-input-mod {
    .emarsys-input-mod-box {
        display: flex;
        max-width: 500px;
        width: 100%;
        margin: 20px auto 0;
        font-size: 16px;
    }
    .emarsys-input-mod-r {
        height: 49px;
        line-height: 49px;
        padding: 0 30px;
        text-align: center;
        white-space: nowrap;
        border-radius: 0px 4px 4px 0px;
        background: #3F68E0;
        color: #fff;
        cursor: pointer;
        font-family: var(--ff-woff2-B);
    }
    .emarsys-input-mod-l {
        height: 49px;
        flex: 1;
        font-family: var(--ff-woff2-M);
    }
    .emarsys-input-mod-input {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        outline: none;
        border-radius: 4px 0 0 4px;
        border: 1px solid #F5F5F5;
        background-color: #F5F5F5;
        box-sizing: border-box;
        color: #000;
        &::placeholder {
            color: #707070;
        }
        &:focus {
            border-color: #3F68E0;
        }
    }
    .emarsys-input-mod-tips {
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        .emarsys-input-mod-tips-fail {
            display: none;
            color: red;
            margin-top: 10px;
        }
        .emarsys-input-mod-tips-success {
            display: none;
            color: green;
            margin-top: 10px;
        }
        &.emarsys-input-mod-tips-fail-no_check {
            .emarsys-input-mod-tips-no_check {
                display: block;
            }
        }
        &.emarsys-input-mod-tips-fail-empty {
            .emarsys-input-mod-tips-tips_error_1 {
                display: block;
            }
        }
        &.emarsys-input-mod-tips-fail-reg {
            .emarsys-input-mod-tips-tips_error_1 {
                display: block;
            }
        }
        &.emarsys-input-mod-tips-success {
            .emarsys-input-mod-tips-success {
                display: block;
            }
        }
    }
    .emarsys-input-mod-protocol {
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        text-align: left;
        cursor: pointer;
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        color: #949494;
        .emarsys-input-mod-protocol-l {
            display: flex;
            align-items: center;
            margin-right: 8px;
            width: 21px;
            height: 20px;
            border: 1px solid #707070;
            border-radius: 4px;
            svg {
                display: none;
            }
            
        }
        .emarsys-input-mod-protocol-r {
            flex: 1;
        }
        a {
            color: #3F68E0;
            text-decoration: underline;
        }
        &.emarsys-input-mod-check {
            .emarsys-input-mod-protocol-l {
                border: none;
                svg {
                    display: block;
                }
            }
        }
    } 
    @include devices(desktop) {
        
    }
    @include devices(pad) {
        .emarsys-input-mod-box {
            display: block;
            font-size: 14px;
        }
        .emarsys-input-mod-l, .emarsys-input-mod-r {
            height: 45px;
            line-height: 45px;
        }
        .emarsys-input-mod-r {
            margin-top: 10px;
            border-radius: 4px;
        }
        .emarsys-input-mod-input {
            border-radius: 4px;
        }
    }
}