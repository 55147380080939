.font-family-light {
    font-family: var(--font-family-ExtraLight);
    font-weight: 200;
}

.font-family-regular {
    font-family: var(--font-family-Light);
    font-weight: 300;
}

.font-family-medium {
    font-family: var(--font-family-Regular);
    font-weight: 400;
    @include maxWidth('lg') {
        font-weight: 400 !important;
    }
}

.font-family-semibold {
    font-family: var(--font-family-Medium);
    font-weight: 500;
}

.font-family-semibolder {
    font-family: var(--font-family-SemiBold);
    font-weight: 500;
}

// h2,
.font-family-bold {
    font-family: var(--font-family-Bold);
    font-weight: 600;
    @include maxWidth('lg') {
        font-weight: 500 !important;
    }
}

.font-family-extrabold {
    font-family: var(--font-family-Bold);
    font-weight: 700;
    @include maxWidth('lg') {
        font-weight: 500 !important;
    }
}

.font-family-extralight {
    font-family: var(--font-family-ExtraLight);
}
