.ef-icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}

.ef-icon-arrowright {
    width: 8px;
    height: 14px;
    background-size: 100% 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS42IDEyLjMzNmw1LjY1Ny01LjRMLjYgMS41MzYiIHN0cm9rZT0iIzI2NzNmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);

    html[site='sa'] & {
        background-image: none;

        &::after {
            content: '';
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            display: inline-block;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS42IDEyLjMzNmw1LjY1Ny01LjRMLjYgMS41MzYiIHN0cm9rZT0iIzI2NzNmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
            transform: rotate(180deg);
            margin-right: 10px;
        }
    }
}

.ef-more-link {
    font-weight: 400;
    font-family: var(--font-family-Regular);
    color: #2673ff;
}
