html {
    body,
    input,
    button,
    textarea {
        font-family: var(--font-family-Light), $globalFonts;
        font-weight: 300;

        html[site='cn'] & {
            font-weight: 400;
        }
    }
}

.font-family-light {
    font-family: var(--font-family-ExtraLight);
    font-weight: 200;
}

.font-family-regular {
    font-family: var(--font-family-Light);
    font-weight: 300;
}

.font-family-medium {
    font-family: var(--font-family-Regular);
    font-weight: 400;
}

.font-family-semibold {
    font-family: var(--font-family-Medium);
    font-weight: 500;
}

.font-family-semibolder {
    font-family: var(--font-family-SemiBold);
    font-weight: 500;
}

// h2,
.font-family-bold {
    font-family: var(--font-family-Bold);
    font-weight: 600;
}

.font-family-extrabold {
    font-family: var(--font-family-Bold);
    font-weight: 700;
}

.font-family-extralight {
    font-family: var(--font-family-ExtraLight);
}
