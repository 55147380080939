.ef-ani-common-section {
    animation: EfAniCommonSection 1s linear;
    animation-timeline: view(calc(100vh - 100px) 0);
    animation-range: 0 100px;
}
@keyframes EfAniCommonSection {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    50% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

