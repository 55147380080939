@import 'lowcode/common/css/devices.scss';

.editor-temp.editor-resource-img-video {
    display: block;
}
.editor-resource-img-video {
    position: relative;
    --resource-img-video-w: 100%;
    --resource-img-video-aspect-ratio: 1;
}

.editor-resource-img-video-in {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}
.editor-resource-img-video-box {
    width: 100%;
    max-width: var(--resource-img-video-w);
    @supports (aspect-ratio: 1 / 1) {
        aspect-ratio: var(--resource-img-video-aspect-ratio);
    }
    @supports not (aspect-ratio: 1 / 1) {
        height: auto;
    }
    img, video {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
}
// 对齐方式
.editor-resource-img-video-imgPosition-left {
    .editor-resource-img-video-in {
        justify-content: flex-start;
        text-align: left;
    }
}
.editor-resource-img-video-imgPosition-right {
    .editor-resource-img-video-in {
        justify-content: flex-end;
        text-align: right;
    }
}

// 满屏
.editor-resource-img-video-widthLayout-full {
    .editor-resource-img-video-box {
        max-width: 100%;
        img, video {
            width: 100%;
        }
    }
}

// 中间
.editor-resource-img-video-widthLayout-default {
    .editor-resource-img-video-in {
        max-width: 1440px;
        padding: 0 120px;
    }
    @include devices(pad) {
        .editor-resource-img-video-in {
            padding: 0 48px;
        }
    }
    @include devices(h5) {
        .editor-resource-img-video-in {
            padding: 0 24px;
        }
    }
}

// 图片各端
.editor-resource-img-video-h5 {
    display: none;
    @include devices(pad) {
        display: block;
    }
}
.editor-resource-img-video-h5 ~ .editor-resource-img-video-pc {
    @include devices(pad) {
        display: none;
    }
}