
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.lowcodeimg {
    :global {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;

        img {
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
