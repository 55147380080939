@import './locales/en.font.scss';
@import './locales/jp.font.scss';
@import './locales/cn.font.scss';
@import './locales/kr.font.scss';
@import './locales/tw.font.scss';
@import './locales/cis.font.scss';
@import './locales/sa.font.scss';

// 字体
// 针对国家 加载字体
body {
    // en
    --font-family-ExtraBold: 'en-Manrope-ExtraBold', 'Arial', sans-serif;
    --font-family-Bold: 'en-Manrope-Bold', 'Arial', sans-serif;
    --font-family-SemiBold: 'en-Manrope-SemiBold', 'Arial', sans-serif;
    --font-family-Medium: 'en-Manrope-Medium', 'Arial', sans-serif;
    --font-family-Regular: 'en-Manrope-Regular', 'Arial', sans-serif;
    --font-family-ExtraLight: 'en-Manrope-ExtraLight', 'Arial', sans-serif;
    --font-family-Light: 'en-Manrope-Light', 'Arial', sans-serif;
    // en other
    --font-family-Dekko-Regular: 'en-Dekko-Regular', 'Arial', sans-serif;
    --font-family-Bebas-Regular: 'en-Bebas-Regular', 'Arial', sans-serif;

    // 新字体 en/de/fr/it/es等 2023-09-04
    // Extended Bold/Medium/Light
    --ff-woff2-EB: 'EN-HelveticNeue-woff2-EB', 'Arial', sans-serif;
    --ff-woff2-B: 'EN-HelveticNeue-woff2-B', 'Arial', sans-serif;
    --ff-woff2-EM: 'EN-HelveticNeue-woff2-EM', 'Arial', sans-serif;
    --ff-woff2-M: 'EN-HelveticNeue-woff2-M', 'Arial', sans-serif;
    --ff-woff2-EL: 'EN-HelveticNeue-woff2-EL', 'Arial', sans-serif;
    --ff-woff2-L: 'EN-HelveticNeue-woff2-L', 'Arial', sans-serif;

    // Oasis页面 bobo说是钟工和家源的需求，需要用Inter字体 2024/12/11
    --inter-B: 'EN-Inter-B', 'Arial', sans-serif;
    --inter-S: 'EN-Inter-S', 'Arial', sans-serif;
    --inter-M: 'EN-Inter-M', 'Arial', sans-serif;
}

[site='jp'] {
    body {
        --font-family-ExtraBold: 'jp-Ef-Font-ExtraBold', 'Arial', sans-serif;
        --font-family-Bold: 'jp-Ef-Font-Bold', 'Arial', sans-serif;
        --font-family-Regular: 'jp-Ef-Font-Regular', 'Arial', sans-serif;
        --font-family-ExtraLight: 'jp-Ef-Font-ExtraLight', 'Arial', sans-serif;
        --font-family-Light: 'jp-Ef-Font-Light', 'Arial', sans-serif;
        --font-family-Medium: 'jp-Ef-Font-Medium', 'Arial', sans-serif;
        --font-family-SemiBold: 'jp-Ef-Font-SemiBold', 'Arial', sans-serif;

        // 新字体 2023-09-04  // 2024-01-17 @yali 确认降级字重
        --ff-woff2-EB: 'JP-NOTOSANS-woff2-Medium', 'Arial', sans-serif;
        --ff-woff2-B: 'JP-NOTOSANS-woff2-Medium', 'Arial', sans-serif;
        --ff-woff2-EM: 'JP-NOTOSANS-woff2-Regular', 'Arial', sans-serif;
        --ff-woff2-M: 'JP-NOTOSANS-woff2-Regular', 'Arial', sans-serif;
        --ff-woff2-EL: 'JP-NOTOSANS-woff2-Light', 'Arial', sans-serif;
        --ff-woff2-L: 'JP-NOTOSANS-woff2-Light', 'Arial', sans-serif;

        @include maxWidth('lg') {
            --font-family-ExtraBold: 'jp-Ef-Font-SemiBold', 'Arial', sans-serif;
            --font-family-Bold: 'jp-Ef-Font-SemiBold', 'Arial', sans-serif;
            --font-family-Regular: 'jp-Ef-Font-Regular', 'Arial', sans-serif;
            --font-family-ExtraLight: 'jp-Ef-Font-ExtraLight', 'Arial', sans-serif;
            --font-family-Light: 'jp-Ef-Font-Light', 'Arial', sans-serif;
            --font-family-Medium: 'jp-Ef-Font-Medium', 'Arial', sans-serif;
            --font-family-SemiBold: 'jp-Ef-Font-SemiBold', 'Arial', sans-serif;
        }
    }
}

[site='cn'] {
    body {
        --font-family-ExtraBold: 'cn-Ef-Font-ExtraBold', 'Arial', sans-serif;
        --font-family-Bold: 'cn-Ef-Font-Bold', 'Arial', sans-serif;
        --font-family-Regular: 'cn-Ef-Font-Regular', 'Arial', sans-serif;
        --font-family-ExtraLight: 'cn-Ef-Font-ExtraLight', 'Arial', sans-serif;
        --font-family-Light: 'cn-Ef-Font-Light', 'Arial', sans-serif;
        --font-family-Medium: 'cn-Ef-Font-Medium', 'Arial', sans-serif;
        --font-family-SemiBold: 'cn-Ef-Font-SemiBold', 'Arial', sans-serif;

        // 新字体 2023-09-04
        --ff-woff2-EB: 'CN-SourceHanSansCN-woff2-ExtraBold', 'Arial', sans-serif;
        --ff-woff2-B: 'CN-SourceHanSansCN-woff2-Bold', 'Arial', sans-serif;
        --ff-woff2-EM: 'CN-SourceHanSansCN-woff2-SemiBold', 'Arial', sans-serif;
        --ff-woff2-M: 'CN-SourceHanSansCN-woff2-Medium', 'Arial', sans-serif;
        --ff-woff2-EL: 'CN-SourceHanSansCN-woff2-Regular', 'Arial', sans-serif;
        --ff-woff2-L: 'CN-SourceHanSansCN-woff2-Light', 'Arial', sans-serif;
    }
}

[site='tw'] {
    body {
        --font-family-ExtraBold: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun,
            sans-serif;
        --font-family-Bold: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;
        --font-family-Regular: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;
        --font-family-ExtraLight: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun,
            sans-serif;
        --font-family-Light: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;
        --font-family-Medium: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;
        --font-family-SemiBold: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;
    }
}

[site='kr'] {
    body {
        --font-family-ExtraBold: 'kr-Ef-Font-ExtraBold', 'Arial', sans-serif;
        --font-family-Bold: 'kr-Ef-Font-Bold', 'Arial', sans-serif;
        --font-family-Regular: 'kr-Ef-Font-Regular', 'Arial', sans-serif;
        --font-family-ExtraLight: 'kr-Ef-Font-ExtraLight', 'Arial', sans-serif;
        --font-family-Light: 'kr-Ef-Font-Light', 'Arial', sans-serif;
        --font-family-Medium: 'kr-Ef-Font-Medium', 'Arial', sans-serif;
        --font-family-SemiBold: 'kr-Ef-Font-SemiBold', 'Arial', sans-serif;

        // 新字体 2023-09-04
        --ff-woff2-EB: 'KR-NOTOSANS-otf-ExtraBold', 'Arial', sans-serif;
        --ff-woff2-B: 'KR-NOTOSANS-otf-Bold', 'Arial', sans-serif;
        --ff-woff2-EM: 'KR-NOTOSANS-otf-SemiBold', 'Arial', sans-serif;
        --ff-woff2-M: 'KR-NOTOSANS-otf-Medium', 'Arial', sans-serif;
        --ff-woff2-EL: 'KR-NOTOSANS-otf-Regular', 'Arial', sans-serif;
        --ff-woff2-L: 'KR-NOTOSANS-otf-Light', 'Arial', sans-serif;
    }
}

[site='cis'] {
    body {
        // 新字体 2023-09-04
        --ff-woff2-EB: 'RU-NOTOSANS-woff2-ExtraBold', 'Arial', sans-serif;
        --ff-woff2-B: 'RU-NOTOSANS-woff2-Bold', 'Arial', sans-serif;
        --ff-woff2-EM: 'RU-NOTOSANS-woff2-SemiBold', 'Arial', sans-serif;
        --ff-woff2-M: 'RU-NOTOSANS-woff2-Medium', 'Arial', sans-serif;
        --ff-woff2-EL: 'RU-NOTOSANS-woff2-Regular', 'Arial', sans-serif;
        --ff-woff2-L: 'RU-NOTOSANS-woff2-Light', 'Arial', sans-serif;
    }
}

[site='sa'] {
    body {
        // 新字体 2023-09-04
        --ff-woff2-EB: 'MEAR-NOTOSANS-woff2-ExtraBold', 'Arial', sans-serif;
        --ff-woff2-B: 'MEAR-NOTOSANS-woff2-Bold', 'Arial', sans-serif;
        --ff-woff2-EM: 'MEAR-NOTOSANS-woff2-SemiBold', 'Arial', sans-serif;
        --ff-woff2-M: 'MEAR-NOTOSANS-woff2-Medium', 'Arial', sans-serif;
        --ff-woff2-EL: 'MEAR-NOTOSANS-woff2-Regular', 'Arial', sans-serif;
        --ff-woff2-L: 'MEAR-NOTOSANS-woff2-Light', 'Arial', sans-serif;
    }
}

// 其它样式

@import './locales/en.scss';

[site='cn'] {
    @import './locales/cn.scss';
}

[site='tw'] {
    @import './locales/tw.scss';
}

[site='jp'] {
    @import './locales/jp.scss';
}

[site='fr'] {
    @import './locales/fr.scss';
}

[site='de'] {
    @import './locales/de.scss';
}

[site='kr'] {
    @import './locales/kr.scss';
}

[site='eu'] {
    @import './locales/eu.scss';
}

[site='es'] {
    @import './locales/es.scss';
}

[site='it'] {
    @import './locales/it.scss';
}

[site='sa'] {
    @import './locales/sa.scss';
}
