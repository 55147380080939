@import 'lowcode/common/css/devices.scss';

.swiper-pagination {
    position: absolute;
    bottom: 24px;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    .swiper-pagination-bullet {
        position: relative;
        width: 8px;
        height: 8px;
        margin: 0 6px;
        border-radius: 50%;
        cursor: pointer;
        background-color: rgba($color: #000000, $alpha: 0.2);
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #000;
    }
}