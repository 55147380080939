// lg: 1024px
@include maxWidth('lg') {
    .container {
        width: 100%;
    }
    .product-list {
        li {
            .box {
                .title {
                    font-size: 38px !important;
                    line-height: 46px !important;
                }
            }
        }
    }
    .footers {
        padding: 0px 25px !important;
        .column {
            min-width: 0 !important;
        }
    }
    .bottom-content {
        .container {
            .box {
                margin-left: 40px;
            }
        }
    }
    .container {
        .container {
            padding: 0px 25px;
        }
        .detail-nav {
            padding: 0px 25px;
        }
    }
    .section-container {
        max-width: none;
    }
    .dropDownMenu .ant-popover-content {
        width: 100%;
    }
}
