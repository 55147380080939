.ef-swiper {
    position: relative;
    height: 800px;
}

.ef-swiper .ef-swiper-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.ef-swiper .ef-swiper-track {
    position: relative;
    display: flex;
    height: 100%;
    transition: transform 800ms ease 0s;
}

.ef-swiper .ef-swiper-slide {
    position: relative;
    height: 100%;
}

.ef-swiper .ef-swiper-pagination {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    text-align: center;
}
.ef-swiper .ef-swiper-bullet {
    display: inline-block;
    vertical-align: top;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}
.ef-swiper .ef-swiper-bullet-active {
    background-color: #000;
}
