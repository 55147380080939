.barrelRoll,
.fadeIn,
.fadeInUp,
.fadeInUpBig,
.floater,
.heartbeat,
.pulse,
.rollerLeft,
.rollerRight,
.rotateIn,
.rotateInLeft,
.rotateInRight,
.rotation,
.shake,
.sideToSide,
.slideDown,
.slideLeft,
.slideRight,
.slideUp,
.spinner,
.wiggle,
.zoomer,
.zoomerOut {
    visibility: visible !important;
}

.rotation {
    animation-name: rotation;
    -webkit-animation-name: rotation;
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.rotation,
.sideToSide {
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0) translateX(50%) rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg) translateX(50%) rotate(-360deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0) translateX(50%) rotate(0);
    }

    to {
        transform: rotate(360deg) translateX(50%) rotate(-360deg);
    }
}

.sideToSide {
    animation-name: sideToSide;
    -webkit-animation-name: sideToSide;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    animation-iteration-count: infinite;
}

@-webkit-keyframes sideToSide {
    0%,
    100% {
        -webkit-transform: translate(100%, 0);
    }

    50% {
        -webkit-transform: translate(-100%, 0);
    }
}

@keyframes sideToSide {
    0%,
    100% {
        transform: translate(100%, 0);
    }

    50% {
        transform: translate(-100%, 0);
    }
}

.zoomer {
    animation-name: zoomer;
    -webkit-animation-name: zoomer;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0.2, 0.3, 1);
    animation-iteration-count: 1;
}

.zoomer,
.zoomerOut {
    -webkit-animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.5, 0.2, 0.3, 1);
    animation-duration: 1s;
}

@-webkit-keyframes zoomer {
    0% {
        -webkit-transform: scale(0.3);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes zoomer {
    0% {
        transform: scale(0.3);
    }

    100% {
        transform: scale(1);
    }
}

.zoomerOut {
    animation-name: zoomerOut;
    -webkit-animation-name: zoomerOut;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0.2, 0.3, 1);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.rollerRight,
.zoomerOut {
    -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes zoomerOut {
    0% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0);
    }
}

@keyframes zoomerOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.spinner {
    animation-name: spinner;
    -webkit-animation-name: spinner;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.pulse,
.spinner {
    animation-timing-function: linear;
    animation-duration: 2s;
}

@-webkit-keyframes spinner {
    from {
        -webkit-transform: rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(0.9);
        opacity: 0.9;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes pulse {
    0%,
    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.9;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

.shake {
    animation-name: shake;
    -webkit-animation-name: shake;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
}

.barrelRoll,
.shake {
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-duration: 0.4s;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    16%,
    50%,
    83% {
        transform: translateX(-10px);
    }

    33%,
    66% {
        transform: translateX(10px);
    }
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }

    16%,
    50%,
    83% {
        -webkit-transform: translateX(-10px);
    }

    33%,
    66% {
        -webkit-transform: translateX(10px);
    }
}

.barrelRoll {
    animation-name: barrelRoll;
    -webkit-animation-name: barrelRoll;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
}

@keyframes barrelRoll {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes barrelRoll {
    from {
        -webkit-transform: rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.floater {
    animation-name: floater;
    -webkit-animation-name: floater;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
}

.floater,
.wiggle {
    animation-iteration-count: infinite;
}

@keyframes floater {
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(8%);
    }
}

@-webkit-keyframes floater {
    0%,
    100% {
        -webkit-transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(8%);
    }
}

.wiggle {
    animation-name: wiggle;
    -webkit-animation-name: wiggle;
    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
}

@keyframes wiggle {
    0%,
    100% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(4deg);
    }
}

@-webkit-keyframes wiggle {
    0%,
    100% {
        -webkit-transform: rotate(-4deg);
    }

    50% {
        -webkit-transform: rotate(4deg);
    }
}

.pound {
    animation-name: pound;
    -webkit-animation-name: pound;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: infinite;
    visibility: visible !important;
}

.heartbeat,
.pound {
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

@keyframes pound {
    to {
        transform: scale(1.2);
    }
}

@-webkit-keyframes pound {
    to {
        transform: scale(1.2);
    }
}

.heartbeat {
    animation-name: heartbeat;
    -webkit-animation-name: heartbeat;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: infinite;
}

@keyframes heartbeat {
    0%,
    100% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2);
    }

    20% {
        transform: scale(1.4);
    }
}

@-webkit-keyframes heartbeat {
    0%,
    100% {
        -webkit-transform: scale(1);
    }

    10% {
        -webkit-transform: scale(1.2);
    }

    20% {
        -webkit-transform: scale(1.4);
    }
}

.rollerRight {
    animation-name: rollerRight;
    -webkit-animation-name: rollerRight;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.rollerLeft,
.rollerRight {
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes rollerRight {
    0% {
        transform: translateX(-200px) rotate(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) rotate(2turn);
        opacity: 1;
    }
}

@-webkit-keyframes rollerRight {
    0% {
        -webkit-transform: translateX(-200px) rotate(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(2turn);
        opacity: 1;
    }
}

.rollerLeft {
    animation-name: rollerLeft;
    -webkit-animation-name: rollerLeft;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fadeOut,
.rollerLeft {
    -webkit-animation-fill-mode: forwards;
}

@keyframes rollerLeft {
    0% {
        transform: translateX(200px) rotate(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) rotate(-2turn);
        opacity: 1;
    }
}

@-webkit-keyframes rollerLeft {
    0% {
        -webkit-transform: translateX(200px) rotate(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(-2turn);
        opacity: 1;
    }
}

.slideDown {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease;
}

.slideDown,
.slideUp {
    animation-timing-function: ease;
    animation-duration: 1s;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

.slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(100%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

.slideLeft {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease;
}

.slideLeft,
.slideRight {
    animation-timing-function: ease;
}

@keyframes slideLeft {
    0% {
        transform: translateX(150%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: translateX(150%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

.slideRight {
    animation-name: slideRight;
    -webkit-animation-name: slideRight;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease;
}

@keyframes slideRight {
    0% {
        transform: translateX(-150%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideRight {
    0% {
        -webkit-transform: translateX(-150%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.25s;
    -webkit-animation-timing-function: ease;
}

.fadeIn,
.fadeOut {
    animation-timing-function: ease;
    animation-duration: 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transition: all 0.25s;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeOut {
    animation-name: fadeOut;
    -webkit-animation-name: fadeOut;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 0.75s cubic-bezier(0.5, 1, 0.89, 1);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 0.75s cubic-bezier(0.5, 1, 0.89, 1);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 0.75s cubic-bezier(0.5, 1, 0.89, 1);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
        transition: all 0.3s;
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

.rotateInRight {
    animation-name: rotateInRight;
    -webkit-animation-name: rotateInRight;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.rotateInLeft,
.rotateInRight {
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes rotateInRight {
    from {
        -webkit-transform: rotate(0) translateX(100%) rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg) translateX(0) rotate(-360deg);
    }
}

@keyframes rotateInRight {
    from {
        transform: rotate(0) translateX(100%) rotate(0);
    }

    to {
        transform: rotate(360deg) translateX(0) rotate(-360deg);
    }
}

.rotateInLeft {
    animation-name: rotateInLeft;
    -webkit-animation-name: rotateInLeft;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.rotateIn,
.rotateInLeft {
    -webkit-animation-iteration-count: 1;
}

@-webkit-keyframes rotateInLeft {
    from {
        -webkit-transform: rotate(0) translateX(-100%) rotate(0);
    }

    to {
        -webkit-transform: rotate(360deg) translateX(0) rotate(-360deg);
    }
}

@keyframes rotateInLeft {
    from {
        transform: rotate(0) translateX(-100%) rotate(0);
    }

    to {
        transform: rotate(360deg) translateX(0) rotate(-360deg);
    }
}

.rotateIn {
    animation-name: rotateIn;
    -webkit-animation-name: rotateIn;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    animation-iteration-count: 1;
    -webkit-transform-origin: center;
    transform-origin: center;
}

@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -720deg);
        transform: rotate3d(0, 0, 1, -720deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@keyframes rotateIn {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -720deg);
        transform: rotate3d(0, 0, 1, -720deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes mm100previewimgbga {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mm100previewimgbga {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes delta2AniA {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes bling {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes WaveAnimation {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-25px);
    }

    50% {
        transform: translateY(25px);
    }

    75% {
        transform: translateY(-25px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes river2_scale_shake_l {
    0% {
        opacity: 0;
        scale: 0;
        transform: translateX(100px);
    }

    70% {
        opacity: 1;
        scale: 1;
        transform: translateX(30px);
    }

    100% {
        opacity: 0;
        scale: 0;
        transform: translateX(0);
    }
}

@keyframes river2_scale_shake_r {
    0% {
        opacity: 0;
        scale: 0;
        transform: translateX(-100px);
    }

    70% {
        opacity: 1;
        scale: 1;
        transform: translateX(-30px);
    }

    100% {
        opacity: 0;
        scale: 0;
        transform: translateX(0);
    }
}

@keyframes river2_radius {
    0% {
        transform: translateX(0);
        border-radius: 0 10px 10px 0;
    }
    15% {
        transform: translateX(6px);
        border-radius: 0 50% 50% 0;
    }
    30% {
        transform: translateX(0);
        border-radius: 0 10px 10px 0;
    }
    45% {
        transform: translateX(6px);
        border-radius: 0 50% 50% 0;
    }
    60% {
        transform: translateX(0);
        border-radius: 0 10px 10px 0;
    }
    100% {
        transform: translateX(0);
        border-radius: 0 10px 10px 0;
    }
}

@keyframes river2_radius_h5 {
    0% {
        transform: translateX(0);
        border-radius: 0 0.06rem 0.06rem 0;
    }
    15% {
        transform: translateX(0.06rem);
        border-radius: 0 50% 50% 0;
    }
    30% {
        transform: translateX(0);
        border-radius: 0 0.06rem 0.06rem 0;
    }
    45% {
        transform: translateX(0.06rem);
        border-radius: 0 50% 50% 0;
    }
    60% {
        transform: translateX(0);
        border-radius: 0 0.06rem 0.06rem 0;
    }
    100% {
        transform: translateX(0);
        border-radius: 0 0.06rem 0.06rem 0;
    }
}

@keyframes svgScale {
    0% {
        width: 64px;
        height: 64px;
    }
    50% {
        width: 84px;
        height: 84px;
    }
    100% {
        width: 64px;
        height: 64px;
    }
}

@keyframes CSR_H5_E_Ani {
    0% {
        scale: 1;
    }
    25%{
        scale: 1.06;
        box-shadow: 0 0 0.08rem rgba(0,0,0, 1);
    }
    70%{
        scale: 1.06;
        box-shadow: 0 0 0.08rem rgba(0,0,0, 1);
    }
    100%{
        scale: 1;
    }
}

@keyframes CSR_H5_G_Ani {
    0% {
        transform: scale(1);
    }
    20%{
        transform: scale(1);
    }
    45%{
        transform: scale(0.9);
    }
    50%{
        transform: scale(0.95);
    }
    55%{
        transform: scale(0.9);
    }
    80%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes CSR_PC_E_ARROW {
    0% {
        transform: translateY(0)
    }
    100% {
        transform: translateY(18px)
    }
}