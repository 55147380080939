
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/devices.scss';

.ui-check-test {
    #__next {
        position: relative;
    }
    .ui-check-mod-opera {
        position: fixed;
        top: 80px;
        right: 24px;
        z-index: 999999;
        
    }
    .ui-check-mod-move {
        width: 80px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        border: 1px solid #1677ff;
        color: #1677ff;
        cursor: pointer;
    }
    .ui-check-mod-toggle {
        display: block;
        height: 32px;
        line-height: 32px;
        padding: 0 12px;
        border-radius: 4px;
        background-color: #1677ff;
        color: #fff;
        cursor: pointer;
        margin-top: 8px;
    }
}
.ui-check-test {
    .ui-check-mod {
        position: absolute;
        top: var(--ui-check-top);
        left: var(--ui-check-left);
        z-index: 9999;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        display: block;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        margin: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
        border: none;
       
    }

    @include devices(only1920) {
        .ui-check-mod {
            background-image: var(--ui-check-1920);
            background-size: 1920px auto;
        }
    }

    @include devices(only1366) {
        .ui-check-mod {
            background-image: var(--ui-check-1366);
            background-size: 1366px auto;
        }
    }

    @include devices(only768) {
        .ui-check-mod {
            background-image: var(--ui-check-768);
            background-size: 768px auto;
        }
    }

    @include devices(h5) {
        .ui-check-mod {
            background-image: var(--ui-check-375);
            background-size: 375px auto;
        }
    }
        
}

.ui-check-test {
    .ui-check-mod-hide {
        display: none !important;
    }
}