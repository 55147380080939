.swiper {
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 3;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: -25px;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0;
            border-radius: 50%;
            transition: opacity 0.5s;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            // width: 8px;
            // height: 8px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            // border-left: 3px solid #49494B;
            // border-bottom: 3px solid #49494B;
        }
        &:hover {
            &::before {
                opacity: 0.1;
            }
        }
    }
    .swiper-button-prev {
        left: 24px;
        &::after {
            margin-left: 2px;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    .swiper-button-next {
        right: 24px;
        &::after {
            margin-left: -2px;
            transform: translate(-50%, -50%) rotate(-135deg);
        }
    }
}