@import 'lowcode/common/css/devices.scss';


.editor-temp.editor-text-textarea {
    display: block;
}
.editor-text-textarea {
    position: relative;
    --editor-text-textarea-bg-color: transparent;
    --editor-text-textarea-color: #000;
    background-color: var(--editor-text-textarea-bg-color);
    text-align: center;
    .editor-text-textarea-in {
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        line-height: 1.2;
        white-space: pre-line;
        font-family: var(--eco-design-font-M);
        font-size: 16px;
        color: var(--editor-text-textarea-color);
    }
    @include devices(pad) {
        .editor-text-textarea-in {
            padding: 0 48px;
        }
    }
    @include devices(h5) {
        .editor-text-textarea-in {
            padding: 0 24px;
        }
    }
}

.editor-text-textarea.position-left {
    text-align: left;
}
.editor-text-textarea.position-right {
    text-align: right;
}