.eco-swiper-tool {
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 3;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #FFF;
        margin-top: 0;
        transform: translateY(-50%);
        color: #18191B;
        &::after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 14px;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            box-sizing: border-box;
        }
        &.swiper-button-disabled{
            opacity: .25;
        }
    }
    .swiper-button-prev {
        left: 16px;
        &::after {
            margin-left: 2px;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    .swiper-button-next {
        right: 16px;
        &::after {
            transform: translate(-50%, -50%) rotate(-135deg);
            margin-left: -2px;
        }
    }
    .swiper-pagination.swiper-pagination-bullets {
        position: absolute;
        bottom: 0;
        z-index: 3;
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        .swiper-pagination-bullet{
            margin: 0;
            width: 10px;
            height: 10px;
            background-color: #696C71;
            opacity: .4;
            &.swiper-pagination-bullet-active{
                opacity: 1;
            }
        }
    }

    @include devices(desktop){
    }

    @include devices(pad){
        width: 100%;
        .swiper-button-prev, .swiper-button-next{
            display: none;
            width: 40px;
            height: 40px;
        }
        .swiper-pagination{
            gap: 8px;
            padding: 10px 0;
            .swiper-pagination-bullet{
                width: 8px;
                height: 8px;
            }
        }
    }
}

.eco-swiper-tool-theme-black {
    .swiper-button-prev, .swiper-button-next {
        background-color: #000;
        &::after {
            border-color: #fff;
        }
    }
}