// * .TTF或.OTF，适用于Firefox 3.5、Safari、Opera
// * .EOT，适用于Internet Explorer 4.0+
// * .SVG，适用于Chrome、IPhone

// 韩语字体
@font-face {
    font-family: 'kr-Ef-Font-ExtraBold';
    src: url('/fonts/NotoSansKR/e2406ff1791c401bc93e73d9e44e6d2b.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-Bold';
    src: url('/fonts/NotoSansKR/e2406ff1791c401bc93e73d9e44e6d2b.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-Regular';
    src: url('/fonts/NotoSansKR/210989664066c01d8ffdbdf56bb773cd.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-ExtraLight';
    src: url('/fonts/NotoSansKR/277434d967d5f33b857fc3f2dbaff15b.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-Light';
    src: url('/fonts/NotoSansKR/e914a10a1bd0088fb8f743fc7569749f.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-Medium';
    src: url('/fonts/NotoSansKR/32666ae307200b0bcab5553590672bb1.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'kr-Ef-Font-SemiBold';
    src: url('/fonts/NotoSansKR/32666ae307200b0bcab5553590672bb1.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


// 2023-09-04 新字体
@font-face{
    font-family: 'KR-NOTOSANS-otf-ExtraBold';
    src: url('/fonts/NotoSansKR/e2406ff1791c401bc93e73d9e44e6d2b.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'KR-NOTOSANS-otf-Bold';
    src: url('/fonts/NotoSansKR/e2406ff1791c401bc93e73d9e44e6d2b.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'KR-NOTOSANS-otf-SemiBold';
    src: url('/fonts/NotoSansKR/32666ae307200b0bcab5553590672bb1.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'KR-NOTOSANS-otf-Medium';
    src: url('/fonts/NotoSansKR/32666ae307200b0bcab5553590672bb1.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'KR-NOTOSANS-otf-Regular';
    src: url('/fonts/NotoSansKR/210989664066c01d8ffdbdf56bb773cd.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'KR-NOTOSANS-otf-Light';
    src: url('/fonts/NotoSansKR/e914a10a1bd0088fb8f743fc7569749f.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}