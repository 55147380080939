// 模块-商品卡片
.ef-good-card {
    position: relative;
    text-align: center;
    color: var(--navbg);
    overflow: hidden;
    width: 3.55rem;
    height: 3.75rem;
    border-radius: 0.2rem;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;

    .ef-good-card-msgs {
        width: 3rem;
        position: relative;
        z-index: 9;
        margin: 0 auto;
    }

    .ef-good-card-new-tips {
        display: block;
        line-height: 0.36rem;
        font-size: 0.17rem;
        font-family: var(--font-family-SemiBold);
        font-weight: 600;
        color: #fa4500;
        height: 0.28rem;
    }

    .ef-good-card-title {
        font-size: 0.28rem;
        line-height: 0.36rem;
        color: #020814;
        font-family: var(--font-family-Bold);
        font-weight: normal;
        white-space: pre-line;

        html[site='fr'] & {
            font-size: 0.24rem;
            line-height: 0.32rem;
        }

        html[site='cn'] & {
            white-space: pre-line;
            font-weight: 400;
        }

        html[site='jp'] & {
            font-weight: 500;
        }

        html[site='it'] & {
            font-size: 0.24rem;
            line-height: 0.32rem;
            letter-spacing: -0.5px;
        }

        html[site='es'] & {
            font-size: 0.24rem;
            line-height: 0.32rem;
        }
        html[site='sa'] & {
            font-weight: 600;
        }
    }

    .ef-good-card-desc {
        position: relative;
        margin-top: 0.05rem;
        line-height: 0.22rem;
        font-size: 0.14rem;
        color: #020814;
        font-family: var(--font-family-Medium);
        font-weight: 400;
        white-space: pre-line;
    }

    .ef-good-card-link {
        html[site='jp'] & {
            margin-top: 2px;
        }

        margin-top: 0.1rem;
        font-family: var(--font-family-ExtraLight);
        font-weight: 400;
        color: #2673ff;
        font-size: 0.17rem;
        line-height: 0.17rem;
        padding-top: 0;
        padding-bottom: 0;

        .link-btn {
            font-size: 0.17rem;
            line-height: 0.17rem;
            padding-top: 0;
            padding-bottom: 0;
            font-family: var(--font-family-Medium);
            font-weight: 500;

            html[site='cn'] & {
                font-weight: 400;
                line-height: 0.2rem;
            }
            html[site='it'] & {
                height: 0.42rem;
            }
            html[site='sa'] & {
                line-height: 0.25rem;
            }
            html[site='kr'] & {
                line-height: 0.25rem;
                font-size: 0.16rem;
            }
        }
    }

    .ef-good-card-link button > span {
        color: #2673ff;
        html[site='es'] & {
            max-width: 1.8rem;
            white-space: normal;
            align-items: center;
            display: flex;
        }
    }

    .ef-good-card-link-more {
        vertical-align: top;
        color: #2673ff;
        padding-left: 0.06rem;

        html[site='jp'] & {
            vertical-align: 1px;
        }
        html[site='sa'] & {
            vertical-align: -2px;
        }

        svg {
            height: 0.12rem;
            html[site='sa'] & {
                transform: rotate(180deg);
                margin-right: 0.05rem;
            }
        }
    }

    .ef-good-card-img {
        position: relative;
        display: block;
        height: 1.88rem;
        width: 100%;
        margin: 0.4rem auto 0;
    }

    .ef-good-card-full-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        .contain {
            object-fit: contain;
        }
    }
}

// 源生输入框
.ef-input-search-primal {
    position: relative;
    height: 0.44rem;

    .ef-input-search-primal-input {
        font-family: var(--font-family-Medium);
        font-weight: 500;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
        padding: 0 0.56rem;
        font-size: 0.14rem;
        color: #03060b;
        background-color: #ededed;
    }

    .ef-input-search-primal-searchicon {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 0.3rem;
        height: 0.3rem;
        font-size: 0.3rem;
        color: #888;
    }

    .ef-input-search-primal-closeicon {
        position: absolute;
        right: 0.14rem;
        top: 50%;
        transform: translateY(-50%) scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background-color: #bcbcbc;
        color: #fff;
        cursor: pointer;
    }
}

// 个别页面汇集
.ecoflow-mobile {
    .page-search .searchBarContainer-pc {
        display: none !important;
    }

    .page-search .searchBarContainer-h5 {
        display: block;
    }

    .header-logo {
        .h5-logo {
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .pc-logo {
            display: none;
        }
    }

    // 隐藏 header 图标
    .header-store {
        .efButton {
            display: none !important;
        }
    }

    .powerkitpresale-pc {
        display: none;
    }
}

// 配件页： dropdown下拉弹框
.ecoflow-mobile {
    //打开头部时，停止滚动
    .ecoflow-frame-open-mobile-nav {
        height: 100vh;
        overflow: hidden;

        .ecoflow-frame-main {
            overflow: hidden;
            height: 90vh;
        }
    }

    .dropDownMenuH5 {
        width: calc(100vw - 0.28rem);
        left: 0.14rem !important;
        top: 0.64rem !important;
        background: #fff;
        border-radius: 0.08rem;
        overflow: hidden;
        box-shadow: 0 0.16rem 0.3rem rgba(0, 0, 0, 0.1);
        padding: 0;

        .ant-popover-arrow {
            display: none;
        }

        .ant-popover-content {
            height: auto;

            .ant-popover-inner {
                height: auto;

                .ant-popover-inner-content {
                    padding: 0;
                    height: 100%;
                    position: relative;

                    .content {
                        box-shadow: 0 0.16rem 0.3rem -0.12rem rgba(0, 0, 0, 0.1);
                        border-radius: 0.08rem;
                        background: #fff;

                        .cover {
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: -10;
                            background-color: rgb(153, 153, 153);
                        }

                        .closeBtnBox {
                            line-height: 0.44rem;
                            height: 0.44rem;
                            width: 100%;
                            text-align: right;
                            html[site='sa'] & {
                                text-align: left;
                            }

                            .closeBtn {
                                display: inline-block;
                                position: relative !important;
                                height: 0.44rem;
                                width: 0.44rem;
                            }
                        }

                        .categoryProduct {
                            margin-top: 0.06rem;

                            .ant-collapse-header {
                                padding: 0 0.2rem;
                                background: #fff;
                                min-height: 0.44rem;
                                line-height: 1.2;
                                font-weight: 500;
                                font-family: var(--font-family-Bold);
                                font-size: 0.14rem;
                                border-bottom: 1px solid transparent;
                                position: relative;

                                html[site='cn'] & {
                                    font-weight: 500;
                                }

                                .minusPlusIconBox {
                                    width: 0.1rem;
                                    height: 0.1rem;
                                }

                                html[site='sa'] & {
                                    text-align: right;
                                    .minusPlusIconBox {
                                        right: auto;
                                        left: 0.3rem;
                                    }
                                }
                            }

                            .ant-collapse-header > div:first-child {
                                position: absolute !important;
                                top: 50%;
                                right: 0.3rem;
                                overflow: visible !important;
                                font-weight: 500;
                                font-family: var(--font-family-Bold);
                            }

                            .ant-collapse-item:not(:first-child) {
                                border-top: 1px solid rgb(235, 233, 233);
                            }

                            .ant-collapse-item-active {
                                .ant-collapse-header {
                                    border-bottom-color: rgb(235, 233, 233);
                                }
                            }

                            .ant-collapse-content {
                                background: rgb(246, 246, 246);

                                .ant-collapse-content-box {
                                    padding: 0;

                                    .list {
                                        padding: 0 0.2rem;
                                        font-size: 0.14rem;
                                        min-height: 0.44rem;
                                        line-height: 1.2;
                                        color: rgb(68, 68, 68);
                                        font-family: var(--font-family-Medium);
                                        font-weight: 500;

                                        html[site='cn'] & {
                                            font-weight: 400;
                                        }
                                        html[site='sa'] & {
                                            text-align: right;
                                        }

                                        &.activeProduct,
                                        &.activeCategory {
                                            color: rgb(38, 115, 255);
                                            background: rgb(232, 232, 232);
                                        }
                                    }
                                }
                            }
                        }

                        .btnBox {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0.3rem 0;

                            .okBtn {
                                width: 2.07rem;
                                line-height: 0.44rem;
                                height: 0.44rem;
                                background: rgb(38, 115, 255);
                                border-radius: 0.04rem;
                                font-family: var(--font-family-Medium);
                                font-weight: 500;
                                font-size: 0.14rem;
                                color: #fff;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    // support: download 下载页面
    .download-dropdown-h5 {
        background: #fff;
        width: 3.27rem;
        left: 0.24rem !important;
        padding-top: 0.12rem;

        .ant-popover-content {
            border: 1px solid rgba(0, 0, 0, 0.02);
            box-shadow: 0 0.02rem 0.05rem rgba(0, 0, 0, 0.15);
            max-height: 6rem;
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .ant-collapse-no-arrow {
            .ant-collapse-content {
                display: none;
            }
        }

        .ant-popover-content {
            .ant-popover-arrow {
                display: none;
            }
        }

        .ant-popover-inner-content {
            padding: 0;

            .downloadPopoverBox {
                .categoryLists {
                    .ant-collapse-header {
                        padding: 0 0.4rem 0 0.2rem;
                        min-height: 0.44rem;
                        line-height: 1.2;
                        display: flex;
                        align-items: center;
                        font-family: var(--font-family-Medium);
                        font-size: 0.14rem;
                        border-bottom: 1px solid transparent;
                        position: relative;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        html[site='jp'] & {
                            font-weight: bold;
                        }

                        html[site='sa'] & {
                            text-align: right;
                            padding: 0 0.2rem 0 0.4rem;
                        }

                        .minusPlusIconBox {
                            width: 0.1rem;
                            height: 0.1rem;
                            html[site='sa'] & {
                                left: 16px;
                                right: unset;
                            }
                        }
                    }

                    .ant-collapse-content {
                        background: rgb(246, 246, 246);

                        .ant-collapse-content-box {
                            padding: 0;
                        }
                    }

                    .ant-collapse-item-active {
                        .subLists {
                            .ant-collapse-item {
                                font-size: 0.14rem;
                                min-height: 0.44rem;
                                line-height: 1.2;
                                color: rgb(68, 68, 68);
                                font-family: var(--font-family-Medium);
                                font-weight: 500;

                                .ant-collapse-header {
                                    padding: 0 0.2rem;
                                    color: #444;
                                    font-weight: normal;
                                    // background: rgb(246, 246, 246);
                                }
                            }

                            .ant-collapse-item-active {
                                .ant-collapse-header {
                                    // color: rgb(38, 115, 255);
                                    // background: rgb(232, 232, 232);
                                }
                            }
                        }
                    }

                    .ant-collapse-item {
                        .subLists {
                            .ant-collapse-item.defaultActiveCls {
                                .ant-collapse-header {
                                    color: rgb(38, 115, 255);
                                    background: rgb(232, 232, 232);
                                }
                            }
                        }
                    }

                    .sub2Lists {
                        .ant-collapse-item {
                            min-height: 0.4rem !important;
                            .ant-collapse-header {
                                font-size: 0.12rem;
                                min-height: 0.4rem;
                            }
                        }
                    }
                    .ant-collapse-header {
                        text-overflow: unset !important;
                        overflow: unset !important;
                        white-space: normal !important;
                    }
                    .active-l2,
                    .active-l1 {
                        & > .ant-collapse-header {
                            background: rgb(236, 236, 236);
                        }
                    }
                    .active-l3 {
                        & > .ant-collapse-header {
                            color: #2673ff !important;
                            background: rgb(222, 222, 222);
                        }
                    }
                }

                .btnBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.12rem 0;
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                    z-index: 100;

                    .okBtn {
                        width: 2.79rem;
                        line-height: 0.44rem;
                        height: 0.44rem;
                        background: rgb(38, 115, 255);
                        border-radius: 0.04rem;
                        font-family: var(--font-family-Medium);
                        font-weight: 500;
                        font-size: 0.14rem;
                        color: #fff;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.ecoflow-mobile {
    //用于h5头部导航搜索定位
    .headerh5-searchDropdown {
        position: fixed;
        top: 1.12rem !important;
        left: 0.25rem !important;
        z-index: 9999999;
        width: calc(100vw - 0.5rem) !important;
        min-height: calc(100vh - 1.2rem);
        padding-top: 0.2rem;
        background-color: #03060b;

        .ant-select-item {
            color: #ccc;
            border-bottom: 0.5px solid #444;
        }

        .ant-select-item-option-content {
            font-size: 0.15rem;
            line-height: 0.52rem;
        }

        .recommend {
            padding-left: 10px;
            font-size: 0.15rem;
            line-height: 0.15rem;
        }

        .rc-virtual-list {
            height: auto;
            margin-top: 0.05rem;
        }

        .ant-select-item {
            padding-left: 0.1rem;
        }

        .rc-virtual-list-holder-inner {
            transform: translateY(0) !important;
        }
    }
}

@include maxWidth('lg') {
    .ef-input-search-primal {
        .ef-input-search-primal-searchicon {
            left: 0.07rem;
            html[site='sa'] & {
                right: 0.07rem;
                left: auto;
            }
        }

        .ef-input-search-primal-closeicon {
            html[site='sa'] & {
                right: auto;
                left: 0.14rem;
            }
        }

        .ef-input-search-primal-input {
            padding: 0 0.43rem;
        }
    }

    .select-list-item:nth-child(2) {
        .compareDropdown {
            left: -0.6rem !important;
            html[site='sa'] & {
                left: 0 !important;
            }
        }
    }
    .select-list-item:nth-child(1) {
        .compareDropdown {
            html[site='sa'] & {
                right: 0 !important;
            }
        }
    }
}

.img-time-test-box {
    position: relative;
    width: 100%;
    height: 100%;
}

.img-time-test-item1 {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    z-index: 999999999;
    pointer-events: none;
    color: red;
    text-align: center;
}

.ef-select-item .ant-select-item {
    font-family: 'Manrope-Medium', 'Arial', sans-serif;
}
